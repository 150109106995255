@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,600;6..12,700;6..12,800&display=swap');

@font-face {
  font-family: 'Nunito Sans';
  src: url('./Nunito_Sans/static/NunitoSans_10pt-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./Nunito_Sans/static/NunitoSans_10pt-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./Nunito_Sans/static/NunitoSans_10pt-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./Nunito_Sans/static/NunitoSans_10pt-ExtraBold.ttf')
    format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}

body {
  -webkit-font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
  * {
    -webkit-font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';
  }
}
